.provider-card-selected {
    background-color: white !important;
    border-color: #6b1eb6 !important;
    font-weight: bold !important;
}

.provider-card:hover {
    background-color: white;
    border-color: #6b1eb6;
}

.provider-card {
    background-color: #f8f8f8;
    border: 2px solid transparent;
    margin: 0 0 15px 0;
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    height: fit-content;
}

.low-emphasis {
    background-color: white !important;
    padding: 5px !important;
}

.low-emphasis span {
    font-weight: 400 !important;
}

.low-emphasis span div{
    color:#7514BD;
}