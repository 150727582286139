.access-link-wrapper {
    margin: 0 30px;
    display: grid;
    height: 100%;
}

.icon-alignment {
    margin: auto !important;
}

.access-link-footer {
    margin-top: 95px !important;
}