@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..700&display=swap');

:root {
    --page-container-bgcolor: #white;
}

body {
    font-family: 'Nunito' !important;
    color: #434343;
    background: #f6f4f2;
}

* {
    scrollbar-width: thin;
    scrollbar-color: lightgray transparent;
}

    /*Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 3px;
    }

    *::-webkit-scrollbar-track {
        background: transparent;
    }

    *::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 5px;
    }

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.truncated-text {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cursor-pointer {
    cursor: pointer;
}

.checkbox {
    margin-right: 7px;
    border-color: black;
    cursor: pointer;
}

.input-context-button {
    position: absolute;
    right: -10px;
    top: -10px;
}

.username-input-field-disabled {
    color: grey;
}

.button-no-hover-color {
    --bs-btn-hover-bg: none !important;
    --bs-btn-active-bg: none !important;
}


/* Responsive layout */
@media (max-width: 576px) {
    body {
        background-color: #fff;
    }

    .back-and-content {
        width: 100%;
    }

    .content-wrapper {
        justify-content: start !important;
    }

    .login-card {
        border: none !important;
        border-radius: 0px !important;
        width: auto !important;
        min-height: auto !important;
        box-shadow: none !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .legacy-links {
        flex-direction: column;
        align-items: center;
        margin-top: 0 !important;
    }

    .footer {
        margin-top: 15px !important;
    }
}