.mfa-username-display {
    position: relative;
    padding-top: 15px;
}

.security-code-icon {
    width: fit-content;
    float: right;
    margin-left: 5px;
}
