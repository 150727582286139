.logo-container {
    width: 250px;
}

.width-400 {
    max-width: 400px;
}

.width-600 {
    max-width: 600px;
}
