.login-card {
    border-radius: 30px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    min-height: 625px;
    width: 450px;
}

.footer {
    background: #321e4b;
    color: #fff;
}

.revver-icon {
    width: 68%;
    min-height: 75px;
    max-height: 75px;
    margin-top: 36px;
    margin-right: 12px;
}

.divider {
    border-bottom: 1px solid #F6F6F6;
    width: 90%;
    margin-top: 16px;
}

.login-back-button {
    position: absolute;
    top: -40px;
    left: -30px;
}

.region-label {
    left: 0;
    right: 0;
}

.region-label span {
    color: #B4B4B4;
    text-transform: uppercase;
    border: 1px solid #B4B4B4;
    border-radius: 5px;
}

.legacy-links {
    display: flex;
    justify-content: space-evenly;
}

.branding-img-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
}

.branding-img {
    border-radius: 5px;
    background-color: #d9d9d9;
    object-fit: contain !important;
    width: 68%;
}
