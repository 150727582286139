.remember-me-formgroup {
    position: relative;
    float: right;
    margin-bottom: 0 !important;
}

.remember-me-formgroup > label {
    color: #434343;
    font-weight: 400;
}

.revverBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #7514bd;
    border-radius: 10px;
    padding: 12px;
    background-color: #f8f3fc;
    margin: 0;
}

.revverBanner span,
.revverBanner a {
    font-weight: 700;
}

.revverBanner a {
    display: flex;
    align-items: center;
    margin: 0 0 0 4px;
}

@media (max-width: 420px) {
    .revverBanner, .revverBanner a {
        display: block;
        text-align: center;
    }
}
