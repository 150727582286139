.validation-compliance-text {
    position: relative;
    bottom: 4.5px;
    left: 5px;
    font-weight: bold;
}

.display-password-compliance {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-bottom: 10px;
}
